.ModalUpdateUserAvatar .p-dialog-header {
    padding-bottom: 0 !important;
    padding-top: 1rem !important;
}

.ModalUpdateUserAvatar .p-dialog-content {
    padding: 0 !important;
}

.min-h-65rem {
    min-height: 65vh;
}