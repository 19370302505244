.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* CUSTOM CSS */
body.no-scroll {
  overflow: hidden !important;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #eb008b #f4f4f4;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: #f4f4f4;
}

*::-webkit-scrollbar-thumb {
  background-color: #eb008b;
  border-radius: 10px;
  border: 3px solid #f4f4f4;
}

a {
  text-decoration: none;
}

.logo {
  width: 150px;
}

.menu-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-wrapper .grid {
  flex: 1;
}

.bg-triangles2 {
  background-image: url("https://img.freepik.com/free-vector/white-background-with-triangle-patterns_1017-18410.jpg?w=996&t=st=1669971843~exp=1669972443~hmac=12dd4cd2690015e27a5b9f4b637d19226df76a2c30253dad818a8b7313406ec4");
}

.bg-isometric {
  background-color: #f4f4f4 !important;
  background-image: linear-gradient(30deg, #fafafa 12%, transparent 12.5%, transparent 87%, #fafafa 87.5%, #fafafa), linear-gradient(150deg, #fafafa 12%, transparent 12.5%, transparent 87%, #fafafa 87.5%, #fafafa), linear-gradient(30deg, #fafafa 12%, transparent 12.5%, transparent 87%, #fafafa 87.5%, #fafafa), linear-gradient(150deg, #fafafa 12%, transparent 12.5%, transparent 87%, #fafafa 87.5%, #fafafa), linear-gradient(60deg, #fafafa77 25%, transparent 25.5%, transparent 75%, #fafafa77 75%, #fafafa77), linear-gradient(60deg, #fafafa77 25%, transparent 25.5%, transparent 75%, #fafafa77 75%, #fafafa77) !important;
  background-size: 80px 140px !important;
  background-position: 0 0, 0 0, 40px 70px, 40px 70px, 0 0, 40px 70px !important;
}

.bg-isometric-pink {
  background-color: #fc0093 !important;
  opacity: 1;
  background-image: linear-gradient(30deg, #fda1d6 12%, transparent 12.5%, transparent 87%, #fda1d6 87.5%, #fda1d6), linear-gradient(150deg, #fda1d6 12%, transparent 12.5%, transparent 87%, #fda1d6 87.5%, #fda1d6), linear-gradient(30deg, #fda1d6 12%, transparent 12.5%, transparent 87%, #fda1d6 87.5%, #fda1d6), linear-gradient(150deg, #fda1d6 12%, transparent 12.5%, transparent 87%, #fda1d6 87.5%, #fda1d6), linear-gradient(60deg, #fda1d677 25%, transparent 25.5%, transparent 75%, #fda1d677 75%, #fda1d677), linear-gradient(60deg, #fda1d677 25%, transparent 25.5%, transparent 75%, #fda1d677 75%, #fda1d677) !important;
  background-size: 48px 84px;
  background-position: 0 0, 0 0, 24px 42px, 24px 42px, 0 0, 24px 42px;
}

.bg-triangles {
  --u: 3.65vmin;
  --c1: #ffffff;
  --c2: #fefefe;
  --c3: #f5f5f5;
  --c4: #fcfcfc;
  --c5: #f9f9f9;
  --gp: 50%/calc(var(--u) * 10) calc(var(--u) * 17.67);
  background: conic-gradient(from 90deg at 99% 67%, var(--c5) 0 90deg, #fff0 0 360deg) var(--gp), conic-gradient(from 180deg at 1% 67%, var(--c5) 0 90deg, #fff0 0 360deg) var(--gp), conic-gradient(from 0deg at 99% 33%, var(--c5) 0 90deg, #fff0 0 360deg) var(--gp), conic-gradient(from -90deg at 1% 33%, var(--c5) 0 90deg, #fff0 0 360deg) var(--gp), conic-gradient(from -60deg at 50% 15.5%, var(--c3) 0 120deg, #fff0 0 360deg) var(--gp), conic-gradient(from -60deg at 50% 16.75%, var(--c5) 0 120deg, #fff0 0 360deg) var(--gp), conic-gradient(from 120deg at 50% 83.25%, var(--c5) 0 120deg, #fff0 0 360deg) var(--gp), linear-gradient(32deg, #fff0 0 49.5%, var(--c5) 0 50.5%, #fff0 0 100%) var(--gp), linear-gradient(-32deg, #fff0 0 49.5%, var(--c5) 0 50.5%, #fff0 0 100%) var(--gp), linear-gradient(-90deg, #fff0 0 49%, var(--c5) 0 51%, #fff0 0 100%) var(--gp), linear-gradient(60.5deg, #fff0 0 49.5%, var(--c5) 0 50.5%, #fff0 0 100%) var(--gp), linear-gradient(-60.5deg, #fff0 0 49.5%, var(--c5) 0 50.5%, #fff0 0 100%) var(--gp), conic-gradient(from -90deg at 50% 50%, var(--c5) 0 32deg, var(--c3) 0 60.5deg, var(--c4) 0 90deg, var(--c5) 0 119.5deg, var(--c1) 0 148deg, var(--c5) 0 180deg, #fff0 0 360deg) var(--gp), conic-gradient(from 90deg at 50% 50%, var(--c2) 0 32deg, var(--c4) 0 60.5deg, var(--c3) 0 90deg, var(--c1) 0 119.5deg, var(--c5) 0 148deg, var(--c2) 0 180deg, #fff0 0 360deg) var(--gp);
  background-color: var(--c5);
}

.main-menu-button {
  background: #fff;
  color: var(--primary-color);
}

.main-menu-button:hover {
  color: #fff;
  background-color: var(--primary-color);
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.page-wrapper {
  flex: 1;
}

[disabled] {
  pointer-events: none;
  opacity: 0.5;
  text-decoration: none;
}

/* .p-chart canvas{
  width: 100% !important
} */
