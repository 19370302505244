.p-tabview .p-tabview-panels {
  background: initial !important;
}

.p-card .p-card-title {
  font-size: 1em !important;
  text-transform: lowercase;
}

.p-card .p-card-title:first-letter {
  text-transform: capitalize;
}

.p-scrollpanel.custom .p-scrollpanel-wrapper {
  border-right: 9px solid #f4f4f4;
}

.p-scrollpanel.custom .p-scrollpanel-bar {
  background-color: var(--primary-color);
  opacity: 0.3;
  transition: background-color .3s;
}

.p-scrollpanel.custom .p-scrollpanel-bar:hover {
  background-color: var(--primary-hover-color);
}

.day-card .p-card-title {
  font-size: 1.2em !important;
}

.day-card .p-card-body {
  padding: 0.5rem 1rem !important;
}

.t-shadow {
  -ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=0, Direction=135, Color=#000000)";
  /*IE 8*/
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
  /* FF3.5+, Opera 9+, Saf1+, Chrome, IE10 */
  filter: progid:DXImageTransform.Microsoft.Shadow(Strength=0, Direction=135, Color=#000000);
  /*IE 5.5-7*/
}