.p-carousel .p-carousel-content .p-carousel-prev, .p-carousel .p-carousel-content .p-carousel-next {
  position: fixed;
  bottom: 0%;
  z-index: 1;
  border-radius: 0 !important;
  margin: 0 !important;
  color: #fff !important;
  background: var(--primary-color) !important;
}

.p-carousel .p-carousel-content .p-carousel-prev {
  left: 0;
}

.p-carousel .p-carousel-content .p-carousel-next {
  right: 0;
}

#menu-dates {
  width: 100%;
}

.day-name {
  /* position: fixed;
    bottom: 0; */
  background: #fff;
  width: 100%;
  text-align: center;
  /* z-index:1; */
}

#menu-overlay {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: #ffffff;
  z-index: 2;
  overflow-y: auto;
}

#diet-plan-page-wrapper {
  position: relative;
  z-index: 1;
}

#diet-plan-page-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  z-index: -1;
  background: url(../../assets/images/food-frame.png);
  background-size: cover;
  background-position: center center;
}

@media (max-width: 575px) {
  .tabViewDiets .p-tabview-panels {
    padding: 0 !important;
  }
  .tabViewDiets .p-tabview-panels .menu-tab {
    padding: 1rem !important;
  }
} 

.tabViewDiets .p-tabview-panels {
  padding-bottom: 0 !important;
}

.dayMenus {
  display:flex ;
  scroll-snap-type: x mandatory;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.container-Day-Menu {
  scroll-snap-align: start;
  position:relative;
  display:block;
}

@media (max-width: 480px) {
  .container-Day-Menu {
    min-width: 100%;
  }
}

@media (min-width: 481px) and (max-width: 600px) {
  .container-Day-Menu {
    min-width: 50%;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .container-Day-Menu {
    min-width: 33.3%;
  }
}

@media (min-width: 1025px) and (max-width: 1600px) {
  .container-Day-Menu {
    min-width: 25%;
  }
}

@media (min-width: 1601px) {
  .container-Day-Menu {
    min-width: 400px;
  }
}